import fp from "lodash/fp"
import { DateTime } from "luxon"
import type { TFunction } from "react-i18next"

import { getTelemetryTranslation } from "helpers/utils/telemetries"

import type { IRecordWTimestamp, RecordService, RecordType } from "types/dashboard.types"
import type { Device, IDeviceWRelsById, ILessIdByDeviceId } from "types/device.types"
import type { DeviceGroupRef, DeviceGroup } from "types/group.types"
import type { LatLonRecord } from "types/geolocation.types"

type EnsureArray = <T>(value: T[] | undefined) => T[]
const ensureArray: EnsureArray = (value) => value ?? []

type FormatLessIdById = (devices: Device[]) => ILessIdByDeviceId
const formatLessIdById: FormatLessIdById = fp.flow(
  fp.map(({ id, less_id }) => [id, less_id]),
  fp.fromPairs,
)

type GroupRefsById = {
  [id: number]: DeviceGroupRef
}

type GetGroupRefsById = (groups: DeviceGroup[] | undefined) => GroupRefsById
const getGroupRefsById: GetGroupRefsById = fp.flow(
  ensureArray,
  // create a groupRef for each group
  fp.map(({ id, name }) => ({
    id,
    name,
  })),
  // make an object from the array, using id as key: { id: { id, name }, ... }
  fp.keyBy(({ id }) => id),
)

type GroupDeviceRel = {
  groupId: number
  deviceId: number
}

type GroupIdsByDevId = {
  [id: number]: GroupDeviceRel[]
}

type GetGroupDeviceRels = (group: DeviceGroup) => GroupDeviceRel[]
const getGroupDeviceRels: GetGroupDeviceRels = (group) =>
  ensureArray<Device | number>(group?.device_list).map((item: Device | number) => ({
    groupId: group.id,
    deviceId: fp.isNumber(item) ? item : item.id,
  }))

type GetGroupIdsByDevId = (groups: DeviceGroup[] | undefined) => GroupIdsByDevId
const getGroupIdsByDevId: GetGroupIdsByDevId = fp.flow(
  ensureArray,
  fp.flatMap(getGroupDeviceRels),
  fp.groupBy(({ deviceId }) => deviceId),
)

type FormatDevsWRelsByIdResult = {
  devsWGroups?: IDeviceWRelsById
  lessIdByDeviceId?: ILessIdByDeviceId
}

type FormatDevsWRelsById = (
  groups: DeviceGroup[] | undefined,
  devices: Device[] | undefined,
) => FormatDevsWRelsByIdResult
export const formatDevsWRelsById: FormatDevsWRelsById = (groups, devices) => {
  if (!devices) {
    return {}
  }
  const lessIdByDeviceId = formatLessIdById(devices)
  const groupRefsById = getGroupRefsById(groups)
  const groupRelsByDevId = getGroupIdsByDevId(groups)

  const devsWGroups = fp.fromPairs(
    devices.map((device) => {
      const rels = fp.getOr([], device.id, groupRelsByDevId)
      const groupsForDevice = rels.map(({ groupId }) => groupRefsById[groupId])
      return [
        device.less_id,
        {
          device,
          // sort the group refs by name
          groups: fp.sortBy(({ name }) => name, groupsForDevice),
        },
      ]
    }),
  )

  return { devsWGroups, lessIdByDeviceId }
}

// Convert _time to Date objects and filter values that are null or undefined
type FormatXYPlotData = (data: RecordType[]) => IRecordWTimestamp[]
export const formatXYPlotData: FormatXYPlotData = (data) => {
  return fp.flatMap(fp.flow(_filterNullValues, _timeToDate), data)
}

type _FilterNullValues = (record: RecordType) => RecordType
const _filterNullValues: _FilterNullValues = fp.flow(
  fp.toPairs,
  fp.filter(([_, value]) => value !== undefined && value !== null),
  fp.fromPairs,
)

type _TimeToDate = (record: RecordType) => IRecordWTimestamp[]
const _timeToDate: _TimeToDate = ({ _time, ...rest }) =>
  fp.isString(_time)
    ? [
        {
          _time: DateTime.fromISO(_time).toJSDate(),
          ...rest,
        },
      ]
    : []

type FormatGroupsById = (groups: DeviceGroup[] | undefined) => {
  [key: number]: { group: DeviceGroup }
}
export const formatGroupsById: FormatGroupsById = fp.flow(
  ensureArray,
  fp.map((group) => [group.id, { group }]),
  fp.fromPairs,
)

type FlattenGroupLocationData = (
  locationData: LatLonRecord[][] | undefined,
) => LatLonRecord[]
export const flattenGroupLocationData: FlattenGroupLocationData = (locationData) => {
  const reducedLocationData = ensureArray(locationData)
    .map((device) => {
      return device.filter((deviceData) => !("less_id" in deviceData))
    })
    .filter((device) => device.length > 0)
    .flat()
  return reducedLocationData
}

type GetRecordService = (fill: string[], t: TFunction) => RecordService
export const getRecordService: GetRecordService = (fill, t) => ({
  getFill: () => fill,
  getKey: genGetRecordKeyByFill(fill),
  getName: genGetRecordName(fill, t),
})

type GetRecordKey = (record: RecordType) => string
type GetRecordName = (record: RecordType) => string

type GenGetRecordKeyByFill = (fill: string[]) => GetRecordKey
const genGetRecordKeyByFill: GenGetRecordKeyByFill = (fill) =>
  fp.flow(fp.at(fill), JSON.stringify)

type GenGetRecordName = (fill: string[], t: TFunction, ignore?: string[]) => GetRecordName
const genGetRecordName: GenGetRecordName = (
  fill,
  t,
  ignore = ["_start", "_stop", "id", "fw", "hw", "type", "ingestor"],
) => {
  const _coerceNameToField = (key: string): string => (key === "name" ? "_field" : key)

  // keys that form name
  const keys: string[] = fp.flow(
    // Drop ignored keys
    fp.difference(fp.__, ignore),
    // We are generating name, so we don't want it here
    fp.map(_coerceNameToField),
    fp.uniq,
  )(fill)

  const getKV = (key: string) => (record: RecordType) => [key, fp.get(key, record)]

  // array of getters, one for each key
  const _getters = fp.map(getKV)(keys)

  // Keep the pairs that form the name
  const getPairs = (record: RecordType) => fp.over(_getters)(record)

  // Drop missing values
  const dropMissing = fp.filter(([_, v]) => v !== undefined && v !== null)

  // Translate _field and keep only the values
  const translateFieldKeepingOnlyValues = fp.flatMap(([k, v]) => {
    if (k === "_field") {
      return [getTelemetryTranslation(String(v), t)]
    }
    return [v]
  })

  const buildStringFromParts = fp.join(", ")

  return (record: RecordType) => {
    return fp.flow(
      getPairs,
      dropMissing,
      translateFieldKeepingOnlyValues,
      buildStringFromParts,
    )(record)
  }
}
